<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0" 
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
           <!-- <CIcon name="cil-user"/> -->
          <img src="@/assets/IMAGES/userprof.png" width="30" height="30"/>

         
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-left" color="light" 
     >

      <strong style="color:black">ACCOUNT</strong>
    </CDropdownHeader> 
    <CDropdownItem to="/account/profile">
      <CIcon name="cil-user"/> View Profile  
    </CDropdownItem>
    <!-- <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tasks
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comments
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <CDropdownHeader
      tag="div"
      class="text-left"
      color="light"
      v-if="config.getPermission('user').allowCrud ||
                  config.getPermission('personnel').allowCrud || 
                  config.getPermission('vehicle').allowCrud || 
                  config.getPermission('customers').allowCrud ||
                  config.getPermission('client_trucker_rates').allowCrud ||
                  config.getPermission('personnel_rates').allowCrud ||
                  config.getPermission('vendor').allowCrud ||
                   config.getPermission('company').allowCrud "
                  
    >
       <strong style="color:black">DATA</strong>
    </CDropdownHeader>
    <CDropdownItem to="/data/personnel" v-if="config.getPermission('personnel').allowCrud">
      <CIcon name="cilPeople" />
      Personnel
    </CDropdownItem>
    <CDropdownItem to="/data/user" v-if="config.getPermission('user').allowCrud">
      <CIcon name="cilUserFemale" />
      Users
    </CDropdownItem>
    <CDropdownItem to="/data/vehicle" v-if="config.getPermission('vehicle').allowCrud"> 
      <CIcon name="cilSpeedometer" />
      Vehicles
    </CDropdownItem> 
    <CDropdownItem to="/data/customer" v-if="config.getPermission('customers').allowCrud">
      <CIcon name="cilLaptop" />
      Customers
    </CDropdownItem>
    <CDropdownItem to="/data/vendor" v-if="config.getPermission('vendor').allowCrud">
      <CIcon name="cilBan" />
      Vendors
    </CDropdownItem>
    <CDropdownItem to="/data/client_rate" v-if="config.getPermission('client_trucker_rates').allowCrud">
      <CIcon name="cilCalculator" />
      Client Trucker Rates
    </CDropdownItem>
    <CDropdownItem to="/data/personnel_rate" v-if="config.getPermission('personnel_rates').allowCrud">
      <CIcon name="cilEuro" />
      Personnel Rates
    </CDropdownItem>

    <CDropdownItem to="/data/company" v-if="config.getPermission('company').allowCrud">
      <CIcon name="cilChartPie" />
      Company
    </CDropdownItem>

    <CDropdownHeader
      tag="div"
      class="text-left"
      color="light"
      v-if="config.getPermission('personnel_type').create && config.getPermission('personnel_type').update && config.getPermission('personnel_type').delete  
           || config.getPermission('booking_type').create && config.getPermission('booking_type').update && config.getPermission('booking_type').delete
           || config.getPermission('commodity_type').create && config.getPermission('commodity_type').update && config.getPermission('commodity_type').delete
           || config.getPermission('vehicle_type').create && config.getPermission('vehicle_type').update && config.getPermission('vehicle_type').delete
           || config.getPermission('commodity_type').create && config.getPermission('commodity_type').update && config.getPermission('commodity_type').delete
           || config.getPermission('origin').create && config.getPermission('origin').update && config.getPermission('origin').delete
            || config.getPermission('destination').create && config.getPermission('destination').update && config.getPermission('destination').delete
            || config.getPermission('depot').create && config.getPermission('depot').update && config.getPermission('depot').delete
            || config.getPermission('maker_model').create && config.getPermission('maker_model').update && config.getPermission('maker_model').delete
            || config.getPermission('vehicle_model').create && config.getPermission('vehicle_model').update && config.getPermission('vehicle_model').delete
            || config.getPermission('destination').create && config.getPermission('destination').update && config.getPermission('destination').delete
           || config.getPermission('part_categories').create && config.getPermission('part_categories').update && config.getPermission('part_categories').delete
           || config.getPermission('part_locations').create && config.getPermission('part_locations').update && config.getPermission('part_locations').delete
           || config.getPermission('user_types').create && config.getPermission('user_types').update && config.getPermission('user_types').delete
          || config.getPermission('service_tasks').create && config.getPermission('service_tasks').update && config.getPermission('service_tasks').delete 
          || config.getPermission('renewal_tasks').create && config.getPermission('renewal_tasks').update && config.getPermission('renewal_tasks').delete 
                   "
      @click="isShown = !isShown">
      <strong style="color:black">SETTINGS</strong>
     <i style="margin-top:4px"
           :class="isShown ?  'fa fa-chevron-up float-right' : 'fa fa-chevron-down float-right'  " />
      <!-- <strong>SETTINGS</strong> -->
    </CDropdownHeader>

    <template v-if="isShown">
    <CDropdownItem to="/settings/booking_type" v-if="config.getPermission('booking_type').allowCrud">
      <CIcon name="cilBasket" />
        Booking Types
    </CDropdownItem>
    <CDropdownItem to="/settings/booking_item_type"  v-if="config.getPermission('booking_item_type').allowCrud">
      <CIcon name="cilBasket" />
        Booking Item Types
    </CDropdownItem>
    <CDropdownItem to="/settings/commodity_type" v-if="config.getPermission('commodity_type').allowCrud">
      <CIcon name="cilFile" />
        Commodity Types
    </CDropdownItem>
    <CDropdownItem to="/settings/depot" v-if="config.getPermission('depot').allowCrud">
      <CIcon name="cilLocationPin" />
        Depots
    </CDropdownItem>
    <CDropdownItem to="/settings/destination" v-if="config.getPermission('destination').allowCrud">
      <CIcon name="cilPuzzle" />
        Destinations
    </CDropdownItem>
    
     <CDropdownItem to="/settings/origins" v-if="config.getPermission('origin').allowCrud">
      <CIcon name="cilLocationPin" />
        Origins
    </CDropdownItem>
    <CDropdownItem to="/settings/personnel_type"  v-if="config.getPermission('personnel_type').allowCrud">
      <CIcon name="cilList" />
        Personnel Types
    </CDropdownItem>
    <CDropdownItem to="/settings/part_category" v-if="config.getPermission('part_categories').allowCrud">
      <CIcon name="cilSettings" />
        Part categories
    </CDropdownItem> 
    <CDropdownItem to="/settings/part_location" v-if="config.getPermission('part_locations').allowCrud"> 
      <CIcon name="cilLocationPin" />
        Part Locations
    </CDropdownItem> 
    <CDropdownItem to="/settings/license_renewal_task">
    <CIcon name="cilUser" />
        Personnel Renewal Tasks
    </CDropdownItem>
     <CDropdownItem to="/settings/renewal_task" > 
      <CIcon name="cilEnvelopeClosed" />
       Asset Renewal Tasks
    </CDropdownItem> 
    <CDropdownItem to="/settings/service_task" > 
      <CIcon name="cilBell" />
        Service Tasks
    </CDropdownItem> 
   
    <CDropdownItem to="/settings/vehicle_type" v-if="config.getPermission('vehicle_type').allowCrud">
      <CIcon name="cilTask" />
        Vehicle Types
    </CDropdownItem> 
    <CDropdownItem to="/settings/maker_model" v-if="config.getPermission('maker_model').allowCrud">
      <CIcon name="cilSpeedometer" />
        Vehicle Makers
    </CDropdownItem>
    <CDropdownItem to="/settings/vehicle_model" v-if="config.getPermission('vehicle_model').allowCrud">
      <CIcon name="cilSpeedometer" />
      Vehicle Models
    </CDropdownItem>
    <CDropdownItem to="/settings/vendor_type" v-if="config.getPermission('vendor_type').allowCrud">
      <CIcon name="cilPeople" />
        Vendor Types
    </CDropdownItem> 
    <CDropdownItem to="/settings/user_type" v-if="config.getPermission('user_types').allowCrud">
    <CIcon name="cilUser" /> 
      User Types
    </CDropdownItem>
    </template>
   
     <hr>
     <template>
    <!-- <CDropdownItem  to="/pages/login">
      <CIcon name="cil-lock-locked"/> 
      Logout
    </CDropdownItem> -->
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked"/> 
      Logout
    </CDropdownItem>

    </template>
    
  </CDropdown>
</template>

<script>
import config from '../views/config.js';
export default {
  
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42,
       isShown: false,
       config
    }
  },
  mounted(){
    var sidebar_el = document.querySelector('.pt-0.dropdown-menu');
    sidebar_el.style.maxHeight = '93vh';
    sidebar_el.style.overflow = 'scroll';
    sidebar_el.style.overflowX = 'hidden'; 
  },
  methods: {
    logout() {
      window.localStorage.removeItem('user_data');
      window.localStorage.removeItem('jwt');
      window.location.reload();
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
  .pt-0.dropdown-menu.show{
    max-height: 90vh !important;
  }
  
</style>