import config from '../views/config.js';

config.getPermission('client billing').allowCrud
config.getPermission('booking').allowCrud
config.getPermission('equipment').allowCrud
config.getPermission('furnishing').allowCrud
config.getPermission('vehicle').allowCrud
config.getPermission('vehicle_reports').allowCrud
config.getPermission('furnishing_reports').allowCrud
config.getPermission('booking_reports').allowCrud
config.getPermission('personnel_settlement_reports').allowCrud
config.getPermission('booking_charges_reports').allowCrud
config.getPermission('opex_reports').allowCrud
config.getPermission('mts_reports').allowCrud
config.getPermission('dr_reports').allowCrud
config.getPermission('equipment_reports').allowCrud
config.getPermission('workorder_reports').allowCrud
config.getPermission('incident').allowCrud
config.getPermission('work_order').allowCrud
config.getPermission('trucker_settlement').allowCrud
config.getPermission('service_reminder').allowCrud
config.getPermission('renewal_reminder').allowCrud
config.getPermission('license_renewal').allowCrud
config.getPermission('part_inventory').allowCrud
config.getPermission('13th_month').allowCrud
config.getPermission('payroll').allowCrud
config.getPermission('adjustment').allowCrud
config.getPermission('personnel_ledger').allowCrud
config.getPermission('ot_late').allowCrud
config.getPermission('working_calendar').allowCrud
config.getPermission('sss').allowCrud

var nav_obj = [{...config.menu}];

console.log(nav_obj)

nav_obj[0]._children[3].items = nav_obj[0]._children[3].items.filter((v, index)=>{
      
      if(v.name?.toLowerCase() == "client billing" && !config.getPermission('client billing').allowCrud)
        return false;
      if(v.name?.toLowerCase() == "trucker settlement" && !config.getPermission('trucker_settlement').allowCrud)
        return false;

      return true;
})

nav_obj[0]._children[6].items = nav_obj[0]._children[6].items.filter((v, index)=>{
      
  if(v.name?.toLowerCase() == "asset renewals" && !config.getPermission('renewal_reminder').allowCrud)
    return false;
  if(v.name?.toLowerCase() == "personnel renewals" && !config.getPermission('license_renewal').allowCrud)
    return false;

  return true;
})

nav_obj[0]._children[4].items = nav_obj[0]._children[4].items.filter((value, index)=>{
  if(value.name == "Equipment" && !config.getPermission('equipment').allowCrud){
    return false;
  }
  if(value.name == "Furnishing" && !config.getPermission('furnishing').allowCrud){
    return false;
  }
  if(value.name == "Vehicles" && !config.getPermission('vehicle').allowCrud){
    return false;
  }
  return true;
})

nav_obj[0]._children[5].items = nav_obj[0]._children[5].items.filter((value, index)=>{
  if(value.name == "Payroll" && !config.getPermission('payroll').allowCrud){
    return false;
  }
  if(value.name == "13th Month" && !config.getPermission('13th_month').allowCrud){
    return false;
  }
  if(value.name == "Personnel Ledger" && !config.getPermission('personnel_ledger').allowCrud){
    return false;
  }
  if(value.name == "Working Calendar" && !config.getPermission('working_calendar').allowCrud){
    return false;
  }
  if(value.name == "Overtime / Late" && !config.getPermission('ot_late').allowCrud){
    return false;
  }
  if(value.name == "SSS Contribution Table" && !config.getPermission('sss').allowCrud){
    return false;
  }
  if(value.name == "Adjustment" && !config.getPermission('adjustment').allowCrud){
    return false;
  }
  return true;
})

nav_obj[0]._children = nav_obj[0]._children.filter((value, index)=>{
	if(value.name == "Bookings" && !config.getPermission('booking').allowCrud){
		return false;
	}
	if(value.name == "Work Order" && !config.getPermission('work_order').allowCrud){
		return false;
	}
	// if(value.name == "Parts Inventory" && !config.getPermission('part_inventory').allowCrud){
	// 	return false;
	// }
	if(value.name == "Fuel Records" && !config.getPermission('fuel_record').allowCrud){
		return false;
	}
  if(value.name == "Inventory Management"
  && !config.getPermission('part_inventory').allowCrud
 ){
return false;
}

if(value.name == "Accounting Management"
                  && !config.getPermission('13th_month').allowCrud
                  && !config.getPermission('payroll').allowCrud
                  && !config.getPermission('personnel_ledger').allowCrud
                  && !config.getPermission('working_calendar').allowCrud
                  && !config.getPermission('ot_late').allowCrud
                  && !config.getPermission('sss').allowCrud
                  && !config.getPermission('adjustment').allowCrud
                 ){
		return false;
	}

	if(value.name == "Billing Management"
                  && !config.getPermission('client billing').allowCrud
                  && !config.getPermission('trucker_settlement').allowCrud){
		return false;
	}

  if(value.name == "Reminders"
                  && !config.getPermission('service_reminder').allowCrud
                  && !config.getPermission('renewal_reminder').allowCrud //asset renewals
                  && !config.getPermission('license_renewal').allowCrud){ //personnel renewal
		return false;
	}
	if (value.name == "Reports" && !config.getPermission('equipment_reports').view && !config.getPermission('equipment_reports').download 
	                          && !config.getPermission('booking_reports').view && !config.getPermission('booking_reports').download  
	                          && !config.getPermission('furnishing_reports').view && !config.getPermission('furnishing_reports').download
	                          && !config.getPermission('vehicle_reports').view && !config.getPermission('vehicle_reports').download
                            && !config.getPermission('workorder_reports').view && !config.getPermission('workorder_reports').download
                            && !config.getPermission('booking_charges_reports').view && !config.getPermission('booking_charges_reports').download
                            && !config.getPermission('personnel_settlement_reports').view && !config.getPermission('personnel_settlement_reports').download
                            && !config.getPermission('dr_reports').view && !config.getPermission('dr_reports').download
                            && !config.getPermission('mts_reports').view && !config.getPermission('mts_reports').download
                            && !config.getPermission('opex_reports').view && !config.getPermission('opex_reports').download
                            && !config.getPermission('fuel_records').view && !config.getPermission('fuel_records').download
                            && !config.getPermission('incident').view && !config.getPermission('incident').download
                            && !config.getPermission('license_renewal').view && !config.getPermission('license_renewal').download



	) {
		return false;
	}
	if(value.name == "Incidents" && !config.getPermission('incident').allowCrud){
		return false;
	}
  
  if(value.name == "Asset Management" && !config.getPermission('equipment').allowCrud 
                                      && !config.getPermission('vehicle').allowCrud 
                                      && !config.getPermission('furnishing').allowCrud){
		return false;
	}

  if(value.name == 'Asset Management' && 
    (config.global_user_data.company && config.global_user_data.company.company_type.toLowerCase().includes('trucking')))
  return false;

  if(value.name == 'Accounting Management' && 
    !(config.global_user_data.company && config.global_user_data.company.company_name.toLowerCase().includes('marvel')))
  return false;

  if(value.name == 'Dashboard' && (!config.getPermission('dashboard').view  || config.global_user_data.company.company_name.toLowerCase().includes('roadwise')))
    return false;

  
  if(value.name == 'Logs' && (!config.getPermission('audit_logs').view  || config.global_user_data.company.company_name.toLowerCase().includes('marvel')))
    return false;
    
  // if(value.name == 'Dashboard' && (!config.has_role(['company admin']) || config.global_user_data.company.company_name.toLowerCase().includes('roadwise')))
  // return false;
  

	return true
})

export default nav_obj;

// export default [
//   {
//     _name: 'CSidebarNav',
//     _children: [
//       {
//         _name: 'CSidebarNavItem',
//         name: 'Dashboard',
//         to: '/dashboard',
//         icon: 'cil-speedometer',
//         // badge: {
//         //   color: 'primary',
//         //   text: 'NEW'
//         // }
//       },
//       {
//         _name: 'CSidebarNavItem',
//         name: 'Bookings',
//         to: '/main/booking',
//         icon: 'cil-chart-pie'
//       },
     
//       {
//         _name: 'CSidebarNavDropdown',
//         name: 'Billing Management',
//         to: '/main',
//         icon: 'cil-list',
//         items: [
//           {
//             name: 'Client Billing',
//             to: '/main/client_billing',
//             icon: 'cil-Dollar',
//           }, 
//         ]
//       },
//       {
//         _name: 'CSidebarNavDropdown',
//         name: 'Asset Management',
//         to: '/main',
//         icon: 'cil-settings',
//         items: [
//           {
//             name: 'Equipment',
//             to: '/main/equipment',
//             icon: 'cil-GlobeAlt',
//           }, 
//           {
//             name: 'Furnishing',
//             to: '/main/furnishing',
//             icon: 'cil-Layers',
//           }, 
//         ]
//       },
//       {
//         _name: 'CSidebarNavItem', 
//         name: 'Incidents',
//         to: '/main/incident',
//         icon: 'cil-LocationPin',
//       },
//       {
//         _name: 'CSidebarNavItem', 
//         name: 'Reports',
//         to: '/main/reports', 
//         icon: 'cil-ChartPie'
//       // },
//       // {
//       //   _name: 'CSidebarNavTitle',
//       //   _children: ['Theme']
//       // },
//       // {
//       //   _name: 'CSidebarNavItem',
//       //   name: 'Colors',
//       //   to: '/theme/colors',
//       //   icon: 'cil-drop'
//       // },
//       // {
//       //   _name: 'CSidebarNavItem',
//       //   name: 'Typography',
//       //   to: '/theme/typography',
//       //   icon: 'cil-pencil'
//       // },
//       // {
//       //   _name: 'CSidebarNavTitle',
//       //   _children: ['Components']
//       // },
//       // {
//       //   _name: 'CSidebarNavDropdown',
//       //   name: 'Base',
//       //   route: '/base',
//       //   icon: 'cil-puzzle',
//       //   items: [
//       //     {
//       //       name: 'Breadcrumbs',
//       //       to: '/base/breadcrumbs'
//       //     },
//       //     {
//       //       name: 'Cards',
//       //       to: '/base/cards'
//       //     },
//       //     {
//       //       name: 'Carousels',
//       //       to: '/base/carousels'
//       //     }, 
//       //     {
//       //       name: 'Collapses',
//       //       to: '/base/collapses'
//       //     },
//       //     {
//       //       name: 'Forms',
//       //       to: '/base/forms'
//       //     },
//       //     {
//       //       name: 'Jumbotrons',
//       //       to: '/base/jumbotrons'
//       //     },
//       //     {
//       //       name: 'List Groups',
//       //       to: '/base/list-groups'
//       //     },
//       //     {
//       //       name: 'Navs',
//       //       to: '/base/navs'
//       //     },
//       //     {
//       //       name: 'Navbars',
//       //       to: '/base/navbars'
//       //     },
//       //     {
//       //       name: 'Paginations',
//       //       to: '/base/paginations'
//       //     },
//       //     {
//       //       name: 'Popovers',
//       //       to: '/base/popovers'
//       //     },
//       //     {
//       //       name: 'Progress Bars',
//       //       to: '/base/progress-bars'
//       //     },
//       //     {
//       //       name: 'Switches',
//       //       to: '/base/switches'
//       //     },
//       //     {
//       //       name: 'Tables',
//       //       to: '/base/tables'
//       //     },
//       //     {
//       //       name: 'Tabs',
//       //       to: '/base/tabs'
//       //     },
//       //     {
//       //       name: 'Tooltips',
//       //       to: '/base/tooltips'
//       //     }
//       //   ]
//       // },
//       // {
//       //   _name: 'CSidebarNavDropdown',
//       //   name: 'Buttons',
//       //   route: '/buttons',
//       //   icon: 'cil-cursor',
//       //   items: [
//       //     {
//       //       name: 'Buttons',
//       //       to: '/buttons/standard-buttons'
//       //     },
//       //     {
//       //       name: 'Button Dropdowns',
//       //       to: '/buttons/dropdowns'
//       //     },
//       //     {
//       //       name: 'Button Groups',
//       //       to: '/buttons/button-groups'
//       //     },
//       //     {
//       //       name: 'Brand Buttons',
//       //       to: '/buttons/brand-buttons'
//       //     }
//       //   ]
//       // },
//       // {
//       //   _name: 'CSidebarNavItem',
//       //   name: 'Charts',
//       //   to: '/charts',
//       //   icon: 'cil-chart-pie'
//       // },
//       // {
//       //   _name: 'CSidebarNavDropdown',
//       //   name: 'Icons',
//       //   route: '/icons',
//       //   icon: 'cil-star',
//       //   items: [
//       //     {
//       //       name: 'CoreUI Icons',
//       //       to: '/icons/coreui-icons',
//       //       badge: {
//       //         color: 'info',
//       //         text: 'NEW'
//       //       }
//       //     },
//       //     {
//       //       name: 'Brands',
//       //       to: '/icons/brands'
//       //     },
//       //     {
//       //       name: 'Flags',
//       //       to: '/icons/flags'
//       //     }
//       //   ]
//       // },
//       // {
//       //   _name: 'CSidebarNavDropdown',
//       //   name: 'Notifications',
//       //   route: '/notifications',
//       //   icon: 'cil-bell',
//       //   items: [
//       //     {
//       //       name: 'Alerts',
//       //       to: '/notifications/alerts'
//       //     },
//       //     {
//       //       name: 'Badges',
//       //       to: '/notifications/badges'
//       //     },
//       //     {
//       //       name: 'Modals',
//       //       to: '/notifications/modals'
//       //     }
//       //   ]
//       // },
//       // {
//       //   _name: 'CSidebarNavItem',
//       //   name: 'Widgets',
//       //   to: '/widgets',
//       //   icon: 'cil-calculator',
//       //   badge: {
//       //     color: 'primary',
//       //     text: 'NEW',
//       //     shape: 'pill'
//       //   }
//       // },
//       // {
//       //   _name: 'CSidebarNavDivider',
//       //   _class: 'm-2'
//       // },
//       // {
//       //   _name: 'CSidebarNavTitle',
//       //   _children: ['Extras']
//       // },
//       // {
//       //   _name: 'CSidebarNavDropdown',
//       //   name: 'Pages',
//       //   route: '/pages',
//       //   icon: 'cil-star',
//       //   items: [
//       //     {
//       //       name: 'Login',
//       //       to: '/pages/login'
//       //     },
//       //     {
//       //       name: 'Register',
//       //       to: '/pages/register'
//       //     },
//       //     {
//       //       name: 'Error 404',
//       //       to: '/pages/404'
//       //     },
//       //     {
//       //       name: 'Error 500',
//       //       to: '/pages/500'
//       //     }
//       //   ]
//       // },
//       // {
//       //   _name: 'CSidebarNavItem',
//       //   name: 'Download CoreUI',
//       //   href: 'http://coreui.io/vue/',
//       //   icon: { name: 'cil-cloud-download', class: 'text-white' },
//       //   _class: 'bg-success text-white',
//       //   target: '_blank'
//       // },
//       // {
//       //   _name: 'CSidebarNavItem',
//       //   name: 'Try CoreUI PRO',
//       //   href: 'http://coreui.io/pro/vue/',
//       //   icon: { name: 'cil-layers', class: 'text-white' },
//       //   _class: 'bg-danger text-white',
//       //   target: '_blank'
//       }
//     ]
//   }
// ]